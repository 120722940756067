import ContentContainer from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/ContentContainer";
import { Button } from "@nextui-org/react";
import Link from "next/link";
import BackgroundImage from "./background.png";
import Image from "next/image";
import ContactFormModal from "../ContactFormModal";
import useDisclosure from "@/hooks/shared/useDisclosure";
const Banner4 = () => {
  const { open, isOpen, close } = useDisclosure();
  return (
    <div className="relative w-full overflow-hidden min-h-[180px] mt-3">
      <Image
        placeholder="blur"
        src={BackgroundImage}
        alt="Background"
        className="-z-10 object-fit-cover w-full h-full position-absolute"
      />

      <div className="relative z-10 flex items-center justify-center h-full full-w align-items-center flex-row my-14">
        <ContentContainer className="w-full" noPx>
          <div className="flex flex-row flex-wrap justify-between w-full md:ml-6 px-8 md:px-0">
            <div className="col-12 col-md-8 px-base lg:px-0">
              <h3 className="font-bold text-3xl text-adahubSecondary">
                CRM đơn giản, dễ sử dụng
              </h3>
              <div className="my-2 mt-3 max-w-[554px]">
                Giải pháp CRM hỗ trợ toàn diện cho các hoạt động Marketing - Bán
                hàng - Chăm sóc khách hàng - Quản lý trên một nền tảng
              </div>
            </div>
            <div className="col-12 col-md-4 items-center justify-center flex flex-row">
              <Button
                // as={Link}
                variant="solid"
                onClick={() => {
                  open();
                }}
                // href="#contact"
                className="bg-adahubSecondary mt-4 md:mt-0 rounded-3xl"
                color="primary">
                Đăng ký tư vấn miễn phí
              </Button>
            </div>
          </div>
        </ContentContainer>
        <ContactFormModal isOpen={isOpen} open={open} close={close} />
      </div>
    </div>
  );
};
export default Banner4;
