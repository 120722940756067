import React from "react";
const AppliedVideoDemo = () => {
  return (
    <div className="w-full mt-4 flex justify-center" style={{ maxWidth: "1200px" }}>
      <iframe
        width="100%"
        className="rounded-xl h-[180px] md:h-[550px] w-[320px] md:w-full"
        height="550"
        src="https://www.youtube.com/embed/9FUBLjwnNsM?si=2tnCYHUOIb_fwZ0J"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen></iframe>
    </div>
  );
};
export default AppliedVideoDemo;