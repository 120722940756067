import ContentContainer from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/ContentContainer";
import logo1 from "./1.png";
import logo2 from "./2.png";
import logo3 from "./3.png";
import logo4 from "./4.png";
import logo5 from "./5.png";
import logo6 from "./6.png";
import logo7 from "./7.png";
import logo8 from "./8.png";
import logo9 from "./9.png";
import logo10 from "./10.png";
import logo11 from "./11.png";
import logo12 from "./12.png";
import logo13 from "./13.png";
import logo14 from "./14.png";
import logo15 from "./15.png";
import logo16 from "./16.png";
import logo17 from "./17.png";
import logo18 from "./18.png";
import logo19 from "./19.png";

import Image from "next/image";
import { Card, CardBody } from "@nextui-org/react";
import { constant, times } from "lodash";
const Customers = ({type = "ucall"}: {type?: "ucall" | "adahub"}) => {
  const listLogo = [
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
    logo10,
    logo11,
    logo12,
    logo13,
    logo14,
    logo15,
    logo16,
    logo17,
    logo18,
    logo19,
  ];
  return (
    <div className="full-size flex flex-column items-center justify-center mt-9 mb-3">
      <ContentContainer>
        <div className="text-center">
          <div className="text-lg mb-6">
            Luôn trân quý niềm tin của khách hàng đặt vào chúng tôi
          </div>
          {type == "adahub" && (<>  <h3 className="font-semibold text-3xl text-adahubSecondary mb-12">
            Adahub tự hào là đối tác tăng tưởng của hơn 2000+ doanh nghiệp bất
            động sản, giáo dục, tài chính, bảo hiểm, thẩm mỹ, bán lẻ,...
          </h3></>)}
          {type == "ucall" && (<>  <h3 className="font-semibold text-3xl text-adahubSecondary mb-12">
            UCALL tự hào là đối tác tăng tưởng của hơn 2000+ doanh nghiệp bất
            động sản, giáo dục, tài chính, bảo hiểm, thẩm mỹ, bán lẻ,...
          </h3></>)}
        </div>
      </ContentContainer>

      <div className="flex flex-row items-center justify-center">
        <div className={`w-full max-w-[1200px] px-4`}>
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-7 justify-items-center gap-x-4 gap-y-4">
            {listLogo.map((e, index) => (
              <div
                key={index}
                className={`w-full ${index >= 14 && index < 15 ? "col-start-1 md:col-start-2" : ""}`}>
                <Card
                  shadow="md"
                  isPressable
                  isHoverable
                  className="flex justify-center items-center min-h-[100px] bg-white">
                  <CardBody className="flex justify-center items-center ">
                    <Image
                      placeholder="blur"
                      src={e}
                      className="max-w-full max-h-full"
                      alt="logo dat xanh"
                    />
                  </CardBody>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
