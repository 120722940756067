
"use client"

import React from "react";
import { Button } from "@nextui-org/react";
import Image, { StaticImageData } from "next/image";
import Image6 from "./image (6).png";
import Image7 from "./image (7).png";
import Image8 from "./image (8).png";
import Image9 from "./image (9).png";
import Link from "next/link";

interface Feature {
  id: number; // Unique identifier for each Feature
  title: string;
  description: React.ReactNode;
  buttonText: string;
  image: StaticImageData; // Assuming images are imported as strings
  alt: string;
  reverse: boolean;
}

// Data array containing all Features
const FeaturesData: Feature[] = [
  {
    id: 1,
    title: "Quản lý khách hàng tập trung và đồng bộ",
    description: (
      <div>
        <p className="mb-6">
          ● Lưu lại{" "}
          <span className="font-bold">
            {" "}
            thông tin chi tiết của từng khách hàng
          </span>{" "}
          theo tên, số điện thoại, địa chỉ, Email hay các kênh khách hàng mua
          hàng
        </p>

        <p className="mb-6">
          ● Cung cấp <span className="font-bold">thông tin chuyên sâu hơn</span>{" "}
          như ghi chú đặc điểm của khách hàng, lịch sử trò chuyện của họ với
          công ty bạn
        </p>
        <p className="mb-6">
          ● Lưu trữ nhanh chóng, rất thuận tiện cho việc{" "}
          <span className="font-bold">tra cứu, sàng lọc</span> cũng như{" "}
          <span className="font-bold">check trùng và hợp nhất</span> đảm bảo
          data khách hàng được quản lý chất lượng
        </p>
        <p>
          ● <span className="font-bold">Cấu hình trường dữ liệu tùy chỉnh</span>{" "}
          theo mọi quy mô, ngành nghề
        </p>
      </div>
    ),
    buttonText: "ĐĂNG KÝ TRẢI NGHIỆM",
    alt: "goi tu dong",
    reverse: false,
    image: Image6,
  },
  {
    id: 2,
    title: "Phân loại theo tập khách hàng",
    description: (
      <div>
        <p className="mb-6">
          ● Phân loại khách hàng{" "}
          <span className="font-bold">theo từng giai đoạn</span>: Trước, trong
          và sau bán hoặc theo nhu cầu, theo sản phẩm ...
        </p>
        <p className="">
          ● Chia khách hàng thành các nhóm thuận tiện cho việc{" "}
          <span className="font-bold">chăm sóc từng nhóm đối tượng</span> khách
          hàng hiệu quả
        </p>
      </div>
    ),
    buttonText: "ĐĂNG KÝ TRẢI NGHIỆM",
    alt: "Giải pháp auto call tích hợp công nghệ AI",
    reverse: true,
    image: Image7,
  },
  {
    id: 3,
    title: "Nuôi dưỡng khách hàng tự động",
    description: (
      <div>
        <p className="mb-6">
          ● Tự động nhắc nhở nhân viên lịch chăm sóc, làm việc với khách hàng
          giúp
          <span className="font-bold">
            {" "}
            giảm thiếu vấn đề rớt, bỏ quên
          </span>{" "}
          khách hàng của đội sale
        </p>

        <p className="mb-6">
          ● Xây dựng các{" "}
          <span className="font-bold">
            kịch bản tự động chăm sóc khách hàng
          </span>{" "}
          theo từng nhóm đã phân loại để tăng tỷ lệ chốt đơn, upsell/ cross sell
        </p>

        <p>
          <span className="font-bold">● Tuỳ chỉnh thời gian tự động</span> gửi
          các tin nhắn chăm sóc khách hàng: gia hạn hợp đồng, công nợ, chúc mừng
          sinh nhật…
        </p>
      </div>
    ),
    buttonText: "ĐĂNG KÝ TRẢI NGHIỆM",
    alt: "goi tu dong",
    reverse: false,
    image: Image8,
  },
  {
    id: 4,
    title: (
      <>
        Cấu hình trường dữ liệu <br />
        tùy chỉnh theo mọi quy mô, ngành nghề{" "}
      </>
    ),
    description: (
      <div>
        <p className="mb-6">
          ● Chủ động tạo mới và quy định các trường thông tin của khách hàng, dễ
          dàng tra cứu với bộ tìm kiếm và bộ lọc nâng cao theo từng trường thông
          tin được thiết lập.
        </p>

        <p className="">
          ● Thiết lập và quản lý hành trình mua hàng theo mô hình phễu bán hàng
          (sales pipeline) phù hợp với mọi mô hình doanh nghiệp: từ cá nhân,
          doanh nghiệp vừa và nhỏ, start-up cho đến lớn và BigCorp
        </p>
      </div>
    ),
    buttonText: "ĐĂNG KÝ TRẢI NGHIỆM",
    alt: "Giải pháp auto call tích hợp công nghệ AI",
    reverse: true,
    image: Image9,
  },
  {
    id: 5,
    title: "Quản lý nhân viên và phân quyền",
    description: (
      <div>
        <p className="mb-6">
          ● Quản lý nhiều phòng ban/ nhóm/ cá nhân cùng lúc theo đặc thù doanh
          nghiệp; dễ dàng thay đổi, mở rộng theo sự phát triển của doanh nghiệp
        </p>

        <p>
          ● Tính năng phân quyền - đảm bảo mọi người dùng đều có quyền truy cập
          vào đúng dữ liệu của mình dựa trên vai trò, cấp bậc trong doanh nghiệp
        </p>
      </div>
    ),
    buttonText: "ĐĂNG KÝ TRẢI NGHIỆM",
    alt: "goi tu dong",
    reverse: false,
    image: Image8,
  },
];

// Props for the FeatureItem component
interface FeatureItemProps {
  title: string;
  description: React.ReactNode;
  buttonText: string;
  image: StaticImageData;
  alt: string;
  reverse: boolean;
}

// FeatureItem Component
const FeatureItem: React.FC<FeatureItemProps> = ({
  title,
  description,
  buttonText,
  image,
  alt,
  reverse,
}) => (
  <div
    className={`row justify-between mt-10 ${reverse ? "flex-row-reverse" : ""}`}>
    <div className="col-12 col-md-6">
      <h4 className="font-semibold text-2xl text-adahubSecondary">{title}</h4>
      <div className="mt-3 whitespace-pre-line">{description}</div>
      <div className="flex justify-center md:justify-start">
        <Button
          as={Link}
          color="primary"
          href="https://app.adahub.vn"
          className="mt-4 mb-4 bg-adahubSecondary rounded-3xl"
          variant="solid">
          {buttonText}
        </Button>
      </div>
    </div>
    <div className="col-12 col-md-6 md:max-w-[448px] ">
      <Image placeholder="blur" src={image} alt={alt} />
    </div>
  </div>
);

const FeaturesCrm: React.FC = () => {
  return (
    <div className="full-size row items-center justify-center mt-16">
      <div className="text-center">
        <h3 className="font-semibold text-3xl text-adahubSecondary">
          Khám phá ngay các tính năng của Adahub CRM
        </h3>
      </div>
      <div className="flex flex-col flex-wrap">
        {FeaturesData.map((Feature) => (
          <FeatureItem
            key={Feature.id}
            title={Feature.title}
            description={Feature.description}
            buttonText={Feature.buttonText}
            image={Feature.image}
            alt={Feature.alt}
            reverse={Feature.reverse}
          />
        ))}
      </div>
    </div>
  );
};

export default FeaturesCrm;
