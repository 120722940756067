"use client";

import { Button } from "@nextui-org/react";

import React from "react";
import EditIcon from "./edit.png";
import CheckCircle from "./check-circle.png";
import GoldIcon from "./gold.png";
import AppStpreIcon from "./appstore.png";
import Image from "next/image";
import Link from "next/link";
import ContactFormModal from "../ContactFormModal";
import useDisclosure from "@/hooks/shared/useDisclosure";
const WhyChooseCRM = () => {
  const { open, isOpen, close } = useDisclosure();
  const contentData = [
    {
      title: "Dễ sử dụng",
      description:
        "Chỉ mất dưới 30 phút để thiết lập và triển khai CRM vào hoạt động kinh doanh của doanh nghiệp. Giao diện CRM thân thiện, trực quan, hiện đại.",
      image: CheckCircle,
    },
    {
      title: "Khả năng tuỳ biến cao",
      description:
        "Adahub cho phép chủ động tạo mới và quy định các trường thông tin của khách hàng  và thiết lập hành trình mua hàng (sales pipeline), phù hợp với mọi mô hình doanh nghiệp: từ cá nhân, doanh nghiệp vừa và nhỏ cho đến lớn và BigCorp.",
      image: EditIcon,
    },
    {
      title: "Tối ưu vận hành",
      description:
        "Doanh nghiệp có thể thiết lập các quy tắc phân chia khách hàng tự động cho nhân viên chăm sóc. Đồng thời, doanh nghiệp có thể thiết lập quy trình chăm sóc khách hàng tự động giúp tối ưu và tự động hóa quy trình vận hành.",
      image: GoldIcon,
    },
    {
      title: "Hệ sinh thái toàn diện",
      description:
        "Hệ sinh thái Adahub cung cấp đầy đủ giải pháp CRM, Callbot AI, Chat đa kênh, Marketing Automation, Email, ZNS, SMS,...",
      image: AppStpreIcon,
    },
  ];
  return (
    <div>
      <h1 className="font-semibold text-3xl text-adahubSecondary text-center my-5">
        Vì sao nên sử dụng CRM của Adahub?
      </h1>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-10">
        {contentData.map((item) => {
          return (
            <>
              <div className="text-start flex flex-col gap-3">
                <Image placeholder="blur" src={item.image} alt="icon phone" />
                <h3 className="text-xl font-bold text-adahubSecondary min-h-[65px]">
                  {item.title}
                </h3>
                <p className="text-[16px]">{item.description}</p>
              </div>
            </>
          );
        })}
      </div>
      <div className="flex flex-col md:flex-row justify-center gap-3 md:gap-10 flex-wrap my-5">
        <Button
          as={Link}
          color="primary"
          href="https://app.adahub.vn"
          className="bg-adahubSecondary"
          variant="solid">
          ĐĂNG KÝ TRẢI NGHIỆM
        </Button>
        <Button
          color="primary"
          onClick={() => {
            open();
          }}
          variant="bordered"
          className="text-adahubSecondary border-adahubSecondary">
          TƯ VẤN MIỄN PHÍ
        </Button>
      </div>
      <ContactFormModal isOpen={isOpen} open={open} close={close} />
    </div>
  );
};

export default WhyChooseCRM;
