import React from "react";
import { Button } from "@nextui-org/react";
import Image, { StaticImageData } from "next/image";
import Group1000004124 from "./Group 1000004124.png";
import Group1000004125 from "./Group 1000004125.png";
import Group1000004127 from "./Group 1000004127.png";
import Link from "next/link";

// Define the structure of each solution item
interface Solution {
  id: number; // Unique identifier for each solution
  title: string;
  description: string;
  buttonText: string;
  image: StaticImageData; // Assuming images are imported as strings
  alt: string;
  reverse: boolean;
}

// Data array containing all solutions
const solutionsData: Solution[] = [
  {
    id: 1,
    title: "Quản lý khách hàng đơn giản, tập trung trên một giao diện",
    description: `Quản lý tập trung toàn bộ dữ liệu về khách hàng, xuyên suốt trước – trong- sau bán, thấu hiểu nhu cầu của khách hàng để cá nhân hóa hoạt động chăm sóc và xúc tiến bán chéo sản phẩm.`,
    buttonText: "Đăng ký tư vấn miễn phí",
    image: Group1000004124,
    alt: "goi tu dong",
    reverse: false,
  },
  {
    id: 2,
    title: "Tăng chất lượng khai thác data nhờ phân loại khách hàng tự động",
    description: `Quản lý data khách hàng tự động phân loại theo nhu cầu - hành vi - nguồn - hành trình khách hàng. Chăm sóc khách hàng theo từng nhóm khách hàng.`,
    buttonText: "Đăng ký tư vấn miễn phí",
    image: Group1000004125,
    alt: "Giải pháp auto call tích hợp công nghệ AI",
    reverse: true,
  },
  {
    id: 3,
    title: "Tự động hóa quy trình bán hàng giúp tiết kiệm thời gian",
    description: `Tối ưu cơ hội bán hàng bằng tự động hóa các công việc lặp lại. Tự động chia cơ hội bán hàng cho nhân viên chăm sóc, tự động nuôi dưỡng khách hàng bằng nhắn tin, ZNS, SMS, Email, Callbot.`,
    buttonText: "Đăng ký tư vấn miễn phí",
    image: Group1000004127,
    alt: "goi tu dong",
    reverse: false,
  },
];

// Props for the SolutionItem component
interface SolutionItemProps {
  title: string;
  description: string;
  buttonText: string;
  image: StaticImageData;
  alt: string;
  reverse: boolean;
}

// SolutionItem Component
const SolutionItem: React.FC<SolutionItemProps> = ({
  title,
  description,
  buttonText,
  image,
  alt,
  reverse,
}) => (
  <div
    className={`row justify-between mt-10 ${reverse ? "flex-row-reverse" : ""}`}>
    <div className="col-12 col-md-6">
      <h4 className="font-semibold text-2xl text-adahubSecondary">{title}</h4>
      <p className="mt-3 whitespace-pre-line">{description}</p>
      <div className="flex justify-center md:justify-start">
        <Button
          as={Link}
          href="#contactHero"
          color="primary"
          scroll={true}
          className="mt-4 mb-4 bg-adahubSecondary rounded-3xl"
          variant="solid">
          {buttonText}
        </Button>
      </div>
    </div>
    <div className="col-12 col-md-6 md:max-w-[448px]">
      <Image placeholder="blur" src={image} alt={alt} />
    </div>
  </div>
);

// Main SolutionsCRM Component
const SolutionsCRM: React.FC = () => {
  return (
    <div className="full-size row items-center justify-center mt-16">
      <div className="text-center">
        <h3 className="font-semibold text-3xl text-adahubSecondary">
          Quản lý toàn bộ khách hàng tập trung giúp
        </h3>
        <h3 className="font-semibold text-3xl text-adahubSecondary">
          doanh nghiệp tối ưu cơ hội bán hàng
        </h3>
      </div>
      <div className="flex flex-col flex-wrap">
        {solutionsData.map((solution) => (
          <SolutionItem
            key={solution.id}
            title={solution.title}
            description={solution.description}
            buttonText={solution.buttonText}
            image={solution.image}
            alt={solution.alt}
            reverse={solution.reverse}
          />
        ))}
      </div>
    </div>
  );
};

export default SolutionsCRM;
